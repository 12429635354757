



















































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { HomeworkController, DropDownController } from '@/services/request.service'
import moment from 'moment'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { i18n } from '@/i18n/i18n'
import filterGroup from '@/components/filterGroup'
import { toPage } from '@/utils/utils'
import { createPagination } from '@/constant/constant'

const operations = {
  courseClass: function(schoolYearId) {
    return DropDownController.getRelatedCourses(schoolYearId, true)
  },
  CCAClass: function(schoolYearId) {
    return DropDownController.getRelatedCcaCourses(schoolYearId, true)
  },
}
@Component({
  components: {
    FlexTooltip,
    filterGroup,
  },
})
export default class HomeworkViewer extends Vue {
  @Prop() private readonly type!: any
  private loading: boolean = false
  private data: any = []
  private moment = moment
  private courses: Array<any> = []
  private schoolYearList: Array<any> = []
  private statuses: Array<any> = ['1021', '1022']
  private showTotal = total => this.$t('common.pageTotal', { total })
  private scoreGradingList: Array<any> = [
    {
      key: 1,
      value: 'A',
    },
    {
      key: 2,
      value: 'B',
    },
    {
      key: 3,
      value: 'C',
    },
    {
      key: 4,
      value: 'D',
    },
    {
      key: 5,
      value: 'E',
    },
  ]
  private filter: any = {
    courseId: 0,
    time: [],
    handInStatus: 0,
    handInOnline: 0,
    schoolYearId: undefined,
  }
  private statusList: any = ['1011', '1012', '1013']
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })

  private get columns(): Array<any> {
    return [
      {
        key: 'name',
        dataIndex: 'name',
        title: this.$t('homework.name'),
        align: 'left',
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      this.type === 'courseClass'
        ? {
            dataIndex: 'subjectName',
            key: 'subjectName',
            ellipsis: true,
            title: this.$t('homework.subjectName'),
            align: 'left',
            scopedSlots: { customRender: 'subjectName' },
          }
        : -1,
      this.type === 'courseClass'
        ? {
            dataIndex: 'courseName',
            key: 'courseName',
            ellipsis: true,
            title: this.$t('homework.courseName'),
            scopedSlots: { customRender: 'courseName' },
            align: 'left',
          }
        : {
            dataIndex: 'courseName',
            key: 'CCAClassName',
            title: this.$t('homework.CCAClass'),
            scopedSlots: { customRender: 'courseName' },
            align: 'left',
          },
      {
        key: 'endTime',
        title: this.$t('homework.deadlineTime'),
        align: 'left',
        width: 180,
        scopedSlots: { customRender: 'endTime' },
      },
      {
        key: 'completeNum',
        title: this.$t('homework.completeNum'),
        align: 'left',
        scopedSlots: { customRender: 'completeNum' },
      },
      {
        key: 'handInOnline',
        title: this.$t('homework.type'),
        align: 'left',
        scopedSlots: { customRender: 'handInOnline' },
      },
      {
        dataIndex: 'creatorName',
        key: 'creatorName',
        title: this.$t('homework.creatorName'),
        ellipsis: true,
        align: 'left',
      },
      {
        key: 'score',
        title: this.$t('homework.score'),
        align: 'right',
        width: 80,
        scopedSlots: { customRender: 'score' },
      },
      {
        key: 'operations',
        title: this.$t('common.operations'),
        align: 'left',
        scopedSlots: { customRender: 'operations' },
      },
    ].filter(item => item !== -1)
  }

  private get currentSchoolYearId(): any {
    return this.$store.state.currentSchoolYear.schoolYearId
  }

  @Watch('type', { immediate: true })
  private onTypeChange(val): void {
    this.reset()
    this.getDropDownInfo()
  }

  private created() {
    this.getSchoolYearList()
  }

  private reset(): void {
    this.filter.courseId = []
    this.filter.time = []
    this.filter.handInStatus = 0
    this.filter.handInOnline = 0
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private viewDetail(homeworkStudentId): void {
    //
    this.$router.push({
      path: 'homeworkDetail',
      name: 'homeworkDetail',
      query: { homeworkStudentId },
    })
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'homework'
  }

  private getDropDownInfo(): void {
    const { schoolYearId } = this.filter
    if (!schoolYearId) return
    this.filter.courseId = this.type === 'courseClass' ? [] : undefined
    operations[this.type](schoolYearId).then(res => {
      if (this.type === 'CCAClass') {
        this.courses = res.data
      } else {
        this.courses = res.data.map(item => {
          return {
            ...item,
            subOptions: item.subOptions.map(it => {
              return {
                key: it.key,
                value: it.value,
                enValue: it.enValue,
                extraValue: it.isTeach,
              }
            }),
          }
        })
      }
    })
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearList()
      .then(res => {
        this.schoolYearList = res.data
        this.filter.schoolYearId = (res.data[0] || {}).key
        // this.filter.schoolYearId = (res.data.filter(item => item.key === this.currentSchoolYearId)[0] || {}).key
        if (this.filter.schoolYearId) {
          this.getDropDownInfo()
        }
      })
      .finally(() => this.getData())
  }

  private changeYear(): void {
    this.$nextTick(() => {
      this.getDropDownInfo()
      this.refresh()
    })
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.loading = true
    this.data = []
    const request = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      courseId: this.filter.courseId !== 0 ? this.filter.courseId : undefined,
      startTime: this.filter.time.length !== 0 ? moment(this.filter.time[0]).valueOf() : undefined,
      endTime: this.filter.time.length !== 0 ? moment(this.filter.time[1]).valueOf() : undefined,
      handInStatus: this.filter.handInStatus !== 0 ? this.filter.handInStatus : undefined,
      handInOnline: this.filter.handInOnline !== 0 ? this.filter.handInOnline : undefined,
      type: this.type === 'courseClass' ? '1041' : '1042',
    }
    HomeworkController.getList(request)
      .then(res => {
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private filterData(inputValue, path) {
    return path.some(option => option.label.indexOf(inputValue) > -1)
  }

  private refresh(): void {
    this.getData(this.pagination)
  }
}
