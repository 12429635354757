









































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { HomeworkController } from '@/services/request.service'
import moment from 'moment'
import { fileUploader } from '@/services/qiniu.service'
import Upload from '@/components/Upload.vue'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'highlight.js/styles/nord.css'

import hljs from 'highlight.js'
import { quillEditor } from 'vue-quill-editor'
import Quill from 'quill'
Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    quillEditor,
    Upload,
  },
})
export default class Diary extends Vue {
  private canLeave: boolean = false
  private uploading: any = false
  private saveDraftLoading: boolean = false
  private saveLoading: boolean = false
  private submited: boolean = false
  private InfoForm: any
  private homeworkStudentId: any
  private homeworkInfo: any = {}
  private attachments: any = []
  private moment = moment
  private statusList: any = ['1011', '1012', '1013']
  private formLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }
  private scoreGradingList: Array<any> = [
    {
      key: 1,
      value: 'A',
    },
    {
      key: 2,
      value: 'B',
    },
    {
      key: 3,
      value: 'C',
    },
    {
      key: 4,
      value: 'D',
    },
    {
      key: 5,
      value: 'E',
    },
  ]
  private options = {
    whiteList: {
      br: [],
    },
  }

  private get editorOption(): any {
    return {
      theme: 'snow',
      modules: {
        toolbar: false,
        syntax: {
          highlieght: text => hljs.highlightAuto(text).value,
        },
      },
    }
  }

  private get infoList(): any {
    return [
      this.type === 'courseClass'
        ? {
            label: this.$t('homework.sectionName'),
            value: this.homeworkInfo.sectionName,
          }
        : -1,
      this.type === 'courseClass'
        ? {
            label: this.$t('homework.subjectName'),
            value: this.homeworkInfo.subjectName,
          }
        : -1,
      {
        label: this.$tc(`homework.${this.type}`),
        value: this.homeworkInfo.courseName,
      },
      {
        label: this.$t('homework.deadlineTime'),
        value: this.homeworkInfo.endTime
          ? moment(this.homeworkInfo.endTime).format('YYYY-MM-DD')
          : '',
      },
      {
        label: this.$t('homework.handInOnline'),
        value: this.$tc(`common.${this.homeworkInfo.handInOnline || false}`),
      },
      {
        label: this.$t('homework.handInAfterDead'),
        value: this.$tc(`homework.${this.homeworkInfo.handInOverDeadline ? 'allow' : 'disallow'}`),
      },
      {
        label: this.$t('homework.publishTime'),
        value: this.homeworkInfo.createTime
          ? moment(this.homeworkInfo.createTime).format('YYYY-MM-DD HH:mm')
          : '',
      },
    ].filter(item => item !== -1)
  }

  private get type(): any {
    return this.$route.params.classType
  }

  private beforeCreate(): void {
    this.InfoForm = this.$form.createForm(this)
  }

  private created(): void {
    this.$nextTick(() => {
      this.homeworkStudentId = this.$route.query.homeworkStudentId
      this.getData(this.homeworkStudentId)
    })
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (
        !this.canLeave &&
        this.homeworkInfo.handInStatus === '1011' &&
        this.homeworkInfo.handInOnline
      ) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        this.canLeave = false
        next()
      }
    } else {
      next()
    }
  }

  private validateAnswer(rule, value, callback): void {
    const form = this.InfoForm
    const content = form.getFieldValue('content')
    const attachments = form.getFieldValue('attachments')
    if (attachments.length === 0 && content === '') {
      callback(this.$t('homework.inputNotBlank'))
    } else {
      callback()
    }
  }

  private saveHomeworkAnswer(): void {
    this.saveLoading = true
    const form = this.InfoForm
    form.validateFields((err: any, values: any) => {
      if (err) {
        this.saveLoading = false
        return
      } else {
        const request = {
          homeworkStudentId: this.homeworkStudentId,
          content: values.content,
          resourceIds: values.attachments.map(item => item.uid),
        }
        HomeworkController.handIn(request)
          .then(res => {
            this.canLeave = true
            this.$message.success(this.$tc('common.saveSuccess'))
            this.getData(this.homeworkStudentId)
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.saveLoading = false
          })
      }
    })
  }

  private saveDraft(): void {
    this.saveDraftLoading = true
    const content = this.InfoForm.getFieldValue('content')
    const resourceIds = this.InfoForm.getFieldValue('attachments').map(item => item.uid)
    const request = {
      homeworkStudentId: this.homeworkStudentId,
      content: content,
      resourceIds: resourceIds,
    }
    HomeworkController.save(request)
      .then(res => {
        this.canLeave = true
        this.$message.success(this.$tc('homework.saveDraftSuccess'))
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.saveDraftLoading = false
      })
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private getData(homeworkStudentId): void {
    HomeworkController.detail(homeworkStudentId)
      .then(res => {
        this.homeworkInfo = res.data
        this.attachments = res.data.handInResources.map(item => {
          return {
            uid: item.resourceId,
            url: item.resourceUrl,
            name: item.resourceName,
            status: 'done',
          }
        })
        this.submited = res.data.handInStatus !== '1011' ? true : false
      })
      .catch(err => {
        console.error(err)
      })
  }
}
